@font-face {
	font-family: 'Monument Regular';
	src:
		url('./fonts/MonumentGrotesk-Regular.woff2') format('woff2'),
		url('./fonts/MonumentGrotesk-Regular.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Monument Semi-Mono';
	src:
		url('./fonts/MonumentGrotesk-Semi-Mono.woff2') format('woff2'),
		url('./fonts/MonumentGrotesk-Semi-Mono.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}
