@font-face {
  font-family: Monument Regular;
  src: url("MonumentGrotesk-Regular.78d1f3b9.woff2") format("woff2"), url("MonumentGrotesk-Regular.834be368.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Monument Semi-Mono;
  src: url("MonumentGrotesk-Semi-Mono.650a660e.woff2") format("woff2"), url("MonumentGrotesk-Semi-Mono.7811eeee.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
/*# sourceMappingURL=index.2b811a97.css.map */
